import React, { ReactNode } from 'react';
import { Spacing, Stack, styled, Typography, useBreakpoints } from 'ui';

export interface SettingsFieldBlockProps {
  readonly title: string;
  readonly description: ReactNode;
  readonly content: ReactNode;
}

const Container = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isLgUp',
})<{ isLgUp: boolean }>`
  flex-direction: ${({ isLgUp }) => (isLgUp ? 'row' : 'column')};
  gap: ${({ theme, isLgUp }) =>
    isLgUp ? theme.spacing(Spacing.XXLarge) : theme.spacing(Spacing.Medium)};
`;

const TitleBlockContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isLgUp',
})<{ isLgUp: boolean }>`
  gap: ${({ theme }) => theme.spacing(Spacing.Small)};
  max-width: ${({ isLgUp }) => (isLgUp ? '328px' : 'unset')};
`;

export function SettingsFieldBlock({
  title,
  description,
  content,
}: SettingsFieldBlockProps) {
  const { isLgUp } = useBreakpoints();

  return (
    <Container isLgUp={isLgUp}>
      <TitleBlockContainer isLgUp={isLgUp}>
        <Typography variant="h5">{title}</Typography>
        {description}
      </TitleBlockContainer>
      {content}
    </Container>
  );
}
