import {
  applyActionCode,
  AuthError,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  getMultiFactorResolver,
  isSignInWithEmailLink,
  multiFactor,
  MultiFactorError,
  MultiFactorResolver,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  signOut as signOutFirebase,
  TotpMultiFactorGenerator,
  TotpSecret,
  updatePassword,
  updatePhoneNumber,
  User,
  verifyPasswordResetCode,
} from 'firebase/auth';
import QRCode from 'qrcode';
import FirebaseManager from './init';

export type { User, AuthError, MultiFactorError, TotpSecret };

export enum AuthenticatorType {
  OTP = 'OTP',
  Phone = 'Phone',
  Nothing = 'Nothing',
}

// Todo: remove container class and export everything as named export.
export class FirebaseAuth {
  private static auth = FirebaseManager.getAuth();

  private static recaptchaVerifier: RecaptchaVerifier;
  private static multiFactorResolver: MultiFactorResolver;

  public static subscribeToUser = (cb: (user: User | null) => void) =>
    onAuthStateChanged(this.auth, cb);

  public static signIn = (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  public static getMultiFactorResolver = (error: MultiFactorError) => {
    this.multiFactorResolver = getMultiFactorResolver(this.auth, error);
  };

  public static errorIsMultiFactorAuthenticationRequired(error: unknown) {
    return (
      (error as MultiFactorError).code === 'auth/multi-factor-auth-required'
    );
  }

  public static initRecaptcha = (elementId: string) => {
    this.recaptchaVerifier = new RecaptchaVerifier(this.auth, elementId, {
      size: 'invisible',
    });
  };

  public static reloadUser = async () => {
    await this.auth.currentUser?.reload();
    return this.auth.currentUser;
  };

  public static getTOTPAuthenticator = async () => {
    const currentUser = this.auth.currentUser!;
    const multiFactorSession = await multiFactor(currentUser).getSession();
    const totpSecret =
      await TotpMultiFactorGenerator.generateSecret(multiFactorSession);

    const totpUri = totpSecret.generateQrCodeUrl(
      currentUser.email!,
      `Claimscore`,
    );
    const qrUrl = await QRCode.toDataURL(totpUri);

    return { qrUrl, totpSecret };
  };

  public static enrollOTP = async (totpSecret: TotpSecret, otp: string) => {
    if (!this.auth.currentUser)
      throw new Error(
        'User must be logged in first with email/pass before validating an authenticator app',
      );

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, otp);
    await multiFactor(this.auth.currentUser).enroll(
      multiFactorAssertion,
      'Authenticator App (Claimscore)',
    );
    return true;
  };

  public static signInWithOTP = async (otp: string) => {
    const multiFactorInfo = this.multiFactorResolver.hints.find(
      (hint) => hint.factorId === 'totp',
    );
    if (!multiFactorInfo) {
      throw new Error('No multi-factor info found.');
    }

    const assertion = TotpMultiFactorGenerator.assertionForSignIn(
      multiFactorInfo.uid,
      otp,
    );
    const authResult = await this.multiFactorResolver.resolveSignIn(assertion);
    return authResult;
  };

  public static resetRecaptcha = () => {
    this.recaptchaVerifier.clear();
  };

  public static enrollPhoneNumber = async (phone: string) => {
    if (!this.auth.currentUser)
      throw new Error(
        'User must be logged in first with email/pass before saving a phone number',
      );
    const multiFactorSession = await multiFactor(
      this.auth.currentUser,
    ).getSession();

    const phoneInfoOptions = {
      phoneNumber: phone,
      session: multiFactorSession,
    };

    const phoneAuthProvider = new PhoneAuthProvider(this.auth);

    // Send SMS verification code.
    const id = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      this.recaptchaVerifier,
    );
    return id;
  };

  public static userHasMultipleEnrollments = () => {
    if (!this.multiFactorResolver) {
      return false;
    }

    return this.multiFactorResolver.hints.length > 1;
  };

  public static getMFAType = () => {
    if (!this.multiFactorResolver) {
      throw new Error('No multi-factor resolver found.');
    }

    if (!this.recaptchaVerifier) {
      throw new Error('No recaptchaVerifier found.');
    }

    const hasAppOTP = Boolean(
      this.multiFactorResolver.hints.find((hint) => hint.factorId === 'totp'),
    );

    const hasPhoneEnrolled = Boolean(
      this.multiFactorResolver.hints.find((hint) => hint.factorId === 'phone'),
    );

    if (hasAppOTP) {
      return AuthenticatorType.OTP;
    }

    if (hasPhoneEnrolled) {
      return AuthenticatorType.Phone;
    }

    return AuthenticatorType.Nothing;
  };

  public static send2FACode = () => {
    if (!this.multiFactorResolver) {
      throw new Error('No multi-factor resolver found.');
    }

    if (!this.recaptchaVerifier) {
      throw new Error('No recaptchaVerifier found.');
    }

    const phoneInfoOptions = {
      multiFactorHint: this.multiFactorResolver.hints.find(
        (hint) => hint.factorId === 'phone',
      ),
      session: this.multiFactorResolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(this.auth);
    return phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      this.recaptchaVerifier,
    );
  };

  public static verify2FACode = (
    verificationId: string,
    verificationCode: string,
    signIn: boolean,
  ) => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    updatePhoneNumber(this.auth.currentUser as User, cred);

    if (signIn)
      return this.multiFactorResolver.resolveSignIn(multiFactorAssertion);

    if (!this.auth.currentUser) {
      throw new Error('No user is currently signed in.');
    }
    return multiFactor(this.auth.currentUser).enroll(
      multiFactorAssertion,
      'My personal phone number',
    );
  };

  public static signUp = (email: string, password: string) => {
    return createUserWithEmailAndPassword(this.auth, email, password);
  };

  public static signOut = () => {
    return signOutFirebase(this.auth);
  };

  public static getAuthToken = () => {
    return this.auth.currentUser?.getIdToken();
  };

  public static getUserId = () => {
    return this.auth.currentUser?.uid as string;
  };

  public static reauthenticateUser = (password: string) => {
    if (!this.auth.currentUser) {
      throw new Error('No user is currently signed in.');
    }
    const credentials = EmailAuthProvider.credential(
      this.auth.currentUser.email as string,
      password,
    );
    return reauthenticateWithCredential(this.auth.currentUser, credentials);
  };

  public static isEmailVerified = async () => {
    await this.auth.currentUser?.reload();
    return Boolean(this.auth.currentUser?.emailVerified);
  };

  public static isClaimScoreApproved = async () => {
    if (!this.auth.currentUser) return false;
    const { claims } = await this.auth.currentUser.getIdTokenResult(true);
    return Boolean(claims.approved);
  };

  public static isSuperAdmin = async () => {
    if (!this.auth.currentUser) return false;
    const { claims } = await this.auth.currentUser.getIdTokenResult(true);
    return Boolean(claims.superAdmin);
  };

  public static verifyPasswordReset = (actionCode: string) => {
    return verifyPasswordResetCode(this.auth, actionCode);
  };

  public static resetPassword = (actionCode: string, password: string) => {
    return confirmPasswordReset(this.auth, actionCode, password);
  };

  public static updateCurrentUserPassword = (password: string) => {
    return updatePassword(this.auth.currentUser as User, password);
  };

  public static verifyEmail = (actionCode: string) => {
    return applyActionCode(this.auth, actionCode);
  };

  public static isSignInWithEmailLink = () =>
    isSignInWithEmailLink(this.auth, window.location.href);

  public static signInWithEmailLink = (email: string) => {
    return signInWithEmailLink(this.auth, email, window.location.href);
  };
}
