import { styled } from '@mui/material';
import React from 'react';
import { Spacing } from '../../design-system/spacing';
import { Button, ButtonProps } from '../Button';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const StyledButtonContent = styled(Button)`
  width: auto;
  height: auto;
  padding: ${({ theme }) => `${theme.spacing(Spacing.Medium)}`};
  border-color: ${({ theme }) => theme.palette.neutral[200]};
  max-width: 258px;
  text-align: start;
`;

interface TabSettingsButtonProps extends Omit<ButtonProps, 'label'> {
  readonly description?: string;
  readonly label: string;
}

export function TabSettingsButton({
  description,
  label,
  ...rest
}: TabSettingsButtonProps) {
  return (
    <StyledButtonContent {...rest}>
      <Stack spacing={Spacing.Small}>
        <Typography color="text.primary" variant="subtitle">
          {label}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          {description}
        </Typography>
      </Stack>
    </StyledButtonContent>
  );
}
