import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ClaimScoreLoader, setupFeatureFlag, useLDClient } from 'ui';
import { FirebaseAuth, User } from 'services/FirebaseService/auth';

interface AuthContextProps {
  user: User | null;
  userPhoneNumber: string;
  isAuthenticated: boolean;
  login: (user: User) => void;
  logout: () => void;
  reloadUser: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  user: null,
  userPhoneNumber: '',
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
  reloadUser: () => undefined,
});

export function AuthProvider({ children }: PropsWithChildren) {
  const [user, setUser] = useState<User | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const launchdarklyClient = useLDClient();
  const [loadingFlags, setLoadingFlags] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);

  const login = useCallback((newUser: User) => {
    setUser(newUser);
    setIsAuthenticated(true);
  }, []);

  const logout = () => {
    FirebaseAuth.signOut();
    setUser(null);
    setIsAuthenticated(false);
  };

  const userPhoneNumber = useMemo(() => {
    const phoneInfo = user?.phoneNumber || '';

    return (
      phoneInfo.replace(
        /^(?<countryCode>\+\d{1,2})(?<areaCode>\d{3})(?<prefix>\d{3})(?<lineNumber1>\d{2})(?<lineNumber2>\d{2})$/u,
        '$<countryCode> ($<areaCode>) $<prefix> $<lineNumber1> $<lineNumber2>',
      ) || 'No phone number added'
    );
  }, [user]);

  const reloadUser = useCallback(() => {
    const reload = async () => {
      if (user) {
        const reloadedUser = await FirebaseAuth.reloadUser();
        if (reloadedUser) setUser({ ...reloadedUser } as User);
      }
    };
    reload();
  }, [user]);

  useEffect(() => {
    const unsubscribe = FirebaseAuth.subscribeToUser((authUser) => {
      if (authUser) {
        login(authUser as User);
      } else {
        logout();
      }
      setLoadingUser(false);
    });
    return () => unsubscribe();
  }, [login]);

  useEffect(() => {
    if (!launchdarklyClient) return;
    if (user) {
      const setup = async () => {
        await setupFeatureFlag({ launchdarklyClient, user });
      };
      setup();
    }
    setLoadingFlags(false);
  }, [launchdarklyClient, user]);

  const authProviderValues = useMemo(
    () => ({
      user,
      userPhoneNumber,
      isAuthenticated,
      isLoading: loadingFlags || loadingUser,
      login,
      logout,
      reloadUser,
    }),
    [
      isAuthenticated,
      userPhoneNumber,
      loadingFlags,
      loadingUser,
      login,
      user,
      reloadUser,
    ],
  );

  if (loadingUser || loadingFlags) {
    return <ClaimScoreLoader />;
  }

  return (
    <AuthContext.Provider value={authProviderValues}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthContextProps => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};
